import { useField, useFormikContext } from 'formik'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'

import SeatSelectionBus from '@components/Seats/Bus'
import SeatSelectionTrain from '@components/Seats/Train'
import config from '@config'
import seat from '@images/extras/seat.png'
import amplitude from '@lib/analytics/amplitude'
import ancillaryUtils from '@lib/ancillary'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import seatsUtils from '@lib/seatSelection'
import Card from '@pages/Checkout/Extras/Card'
import Info from '@pages/Checkout/Extras/Seats/Info'
import ExtrasSkeleton from '@pages/Checkout/Extras/Skeleton'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import useSeatsQueries from '@queries/seats'
import useTwoStepSeatsQuery from '@queries/seats/twoSteps'
import { useSettings } from '@queries/settings'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'
import { Divider, Icon, Skeleton } from '@ui'

import '@pages/Checkout/Extras/Seats/index.scss'

interface SeatsProps {
  onError: (error: ErrorCode | null) => void
  enabled?: boolean
  isConnectionsLoading: boolean
}

type CheckoutSeats = CheckoutFormData['seats']

const getSeatsAmount = (seats?: Seat.BySegment | undefined): number => {
  if (!seats || !Object.values(seats).length) return 0

  return Object.values(seats)[0].length
}

const Warning = ({ title }: { title: string }): ReactElement => (
  <div className="extras__seats-warning-message seats-error row gap-1">
    <Icon name="alert" size="small" />
    <span>{title}</span>
  </div>
)

const SeatsSkeleton = (): ReactElement => (
  <>
    <ExtrasSkeleton />
    <Divider className="mb-3 mt-3" />
  </>
)

const Control = (): ReactElement => (
  <div className={bem('extras', 'seats-icon')}>
    <Icon name="chevron-right" size="large" />
  </div>
)

const Seats = ({ onError, isConnectionsLoading, enabled }: SeatsProps): ReactElement => {
  const { t } = useTranslation()
  const [opened, setOpened] = useState<boolean>(false)
  const [touched, setTouched] = useState<boolean>(false)
  const [{ retailerPartnerNumber, marketingCarrierCode }] = useParams()
  const [{ outbound, inbound }] = useCheckout()

  const [{ reservation, seatSelection }] = useSettings()
  const { enabledOnCheckout, flowType, sendFareClass, mandatory } = seatSelection

  const { values, setFieldValue } = useFormikContext<CheckoutFormData>()
  const { seats, passengers, ancillaries, fareClass, vacancy, isVacancyLoading } = values

  const loadEnabled = enabledOnCheckout || !!enabled
  const isTrain = outbound?.segments[0].vehicle?.vehicleType?.code === 'TRAIN'

  const outboundSeats = useSeatsQueries({
    connection: outbound,
    enabled: flowType === 'single_step' && loadEnabled,
    options: { fareClass: sendFareClass ? fareClass : null },
  })
  const inboundSeats = useSeatsQueries({
    connection: inbound,
    enabled: flowType === 'single_step' && loadEnabled,
    options: { fareClass: sendFareClass ? fareClass : null },
  })
  // istanbul ignore next
  const trainSeatsTwoStep = useTwoStepSeatsQuery({
    connection: outbound,
    enabled: flowType === 'two_step' && loadEnabled,
    options: { fareClass },
    passengers,
  })

  const validate = useCallback(
    (value: CheckoutSeats): string | undefined => {
      const isValidationIrrelevant = !enabledOnCheckout || isTrain || !value?.outbound

      if (isValidationIrrelevant && !mandatory) return

      const divider = Number(!!inbound) + 1
      const amount = getSeatsAmount(value?.outbound) + getSeatsAmount(value?.inbound)

      return amount / divider === passengers.length ? undefined : t('errors.mismatchSeats')
    },
    [enabledOnCheckout, inbound, isTrain, mandatory, passengers.length, t],
  )

  const [_, { error: formError }] = useField({ name: 'seats', validate })

  // istanbul ignore next
  const isLoading =
    flowType === 'single_step' ? outboundSeats[1].isLoading || inboundSeats[1].isLoading : trainSeatsTwoStep.isLoading
  // istanbul ignore next
  const error = flowType === 'single_step' ? outboundSeats[1].error || inboundSeats[1].error : trainSeatsTwoStep.error

  useEffect(() => {
    if (error) {
      onError(error.code)
    }
  }, [error, onError])

  // istanbul ignore next
  const layout = useMemo(
    () => ({ outbound: outboundSeats[0] ?? [], inbound: inboundSeats[0] ?? [] }),
    [outboundSeats, inboundSeats],
  )

  const selectedSeatsCount = seatsUtils.getSeatsCount(seats?.outbound) + seatsUtils.getSeatsCount(seats?.inbound)
  const isAvailable = !config.forcedExtras?.[retailerPartnerNumber]?.seatSelection?.enabled

  const isAncillary = config.seatAncillaryAsSelection[marketingCarrierCode!]
  const seatAncillary = ancillaryUtils.getByCategory('SEAT', vacancy?.ancillaries)[0]

  const trainSeats = useMemo(() => seatsUtils.transformIntoTrainSeats(layout.outbound), [layout.outbound])

  // istanbul ignore next
  const resetSeatSelection = () => {
    setFieldValue('seats', null)
    const ancillary = ancillaries.SEAT?.[0]
    if (isAncillary && ancillary && !ancillaryUtils.isMandatory(ancillary)) {
      setFieldValue('ancillaries.SEAT', [])
    }
    setOpened(false)
  }

  // istanbul ignore next
  useEffect(() => {
    if (isAncillary) {
      resetSeatSelection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy])

  const handleConfirmSelection = ({ seats }: Seat.SubmitData): void => {
    setFieldValue('seats', seats)
    // istanbul ignore next
    if (isAncillary) {
      if (seatsUtils.getSeatsCount(seats.outbound) === 0) {
        setFieldValue('ancillaries.SEAT', [])
      } else {
        setFieldValue('ancillaries.SEAT', [ancillaryUtils.clearAttributes(seatAncillary)])
      }
    }
    setOpened(false)
  }

  // istanbul ignore next
  const handleOpenModal = (): void => {
    if (reservation.enabled) return
    if (!isAvailable) {
      setTouched(true)
      amplitude.checkout.changeAncillary('Seat', passengers.length)
      return
    }

    setOpened(true)
  }

  // istanbul ignore next
  const seatsLoading = isLoading || isConnectionsLoading || (isAncillary && isVacancyLoading)
  const showControls = !reservation.enabled && !error

  const errorMessage = useMemo(() => {
    if (touched) return t('seats.warning.unavailable')
    if (formError) return t('errors.mismatchSeats')

    return null
  }, [t, touched, formError])

  // istanbul ignore next
  if (error) return <></>

  // istanbul ignore next
  return (
    <Skeleton.List Skeleton={() => <SeatsSkeleton />} amount={1} loading={seatsLoading}>
      <div className={bem('extras', 'section')}>
        <Card
          icon={seat}
          title={t('extras.seats.selected', { count: selectedSeatsCount })}
          description={<Info seats={seats} />}
          controls={showControls && <Control />}
          onClick={handleOpenModal}
          warning={errorMessage && <Warning title={errorMessage} />}
          price={isAncillary ? seatAncillary?.price : undefined}
        />
        {!error && !isLoading && loadEnabled && (
          <>
            {flowType === 'single_step' && !isTrain && opened && (
              <SeatSelectionBus
                initialSelection={seats}
                alwaysShowHeader={!!inbound}
                layout={layout}
                connections={{ outbound, inbound }}
                reservedSeatsCount={passengers.length}
                initialFare={fareClass}
                opened
                onClose={() => setOpened(false)}
                fareFilter={fareClass}
                onSubmit={handleConfirmSelection}
                outboundSeatsCount={passengers.length}
              />
            )}
            {flowType === 'single_step' && isTrain && opened && (
              <SeatSelectionTrain
                {...trainSeats}
                allowSkip
                opened
                initialSelection={seats}
                reservedSeatsCount={passengers.length}
                onSubmit={handleConfirmSelection}
                onClose={() => setOpened(false)}
                connection={outbound}
                selectedFare={fareClass}
              />
            )}
            {flowType === 'two_step' && opened && (
              <SeatSelectionTrain
                initialSelection={seats ?? { outbound: trainSeatsTwoStep.preselectedSeats, inbound: [] }}
                layoutQuery={trainSeatsTwoStep.layoutQuery}
                scheme={trainSeatsTwoStep.scheme}
                opened
                reservedSeatsCount={passengers.length}
                onSubmit={handleConfirmSelection}
                onClose={() => setOpened(false)}
                connection={outbound!}
                selectedFare={fareClass}
                onClear={resetSeatSelection}
              />
            )}
          </>
        )}
      </div>
    </Skeleton.List>
  )
}

export default Seats

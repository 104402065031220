import cn from 'classnames'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import React, { useState } from 'react'

import BlockSection from '@components/Blocks/common/BlockSection'
import SubmitModal from '@components/Blocks/common/SubmitModal'
import PrivacyPolicy from '@components/Blocks/Subscribe/PrivacyPolicy'
import { useTranslation } from '@lib/i18n'
import { chain, email, required } from '@lib/validators'
import { useCarrierLoader } from '@loaders/carrier'
import { useFeedbackMutation } from '@loaders/feedback'
import { useParams } from '@stores/params'
import { Button } from '@ui'
import Icon from '@ui/Icon'
import InputField from '@ui/Input/Field'

import '@components/Blocks/Subscribe/index.scss'

type Props = PageBlocks.BlockComponentProps<PageBlocks.SubscribeBlock>

interface FormData {
  email: string
}

const SubscribeBlock = ({ translationKey, layout = 'column', ...rest }: Props) => {
  const { t } = useTranslation()
  const [activeModal, setActiveModal] = useState<'success' | 'error' | null>(null)
  const { title, description } = t<any, PageBlocks.CardTranslation>(`customBlocks.${translationKey}`, {
    returnObjects: true,
    defaultValue: {},
    postProcess: ['parseHtml'],
    parseHtmlOptions: {
      keyPattern: /\.description/,
    },
  })

  const [{ marketingCarrierCode, currency, locale }] = useParams()
  const carrierLoader = useCarrierLoader({
    marketingCarrierCode: marketingCarrierCode,
    params: { currency, locale },
  })

  const feedback = useFeedbackMutation()
  const onSubmit = async (data: FormData) => {
    try {
      /* istanbul ignore next */
      if (!carrierLoader.data?.customerServiceEmail) return setActiveModal('error')
      await feedback.mutateAsync({
        ...data,
        targetEmail: carrierLoader.data.customerServiceEmail,
        theme: t(`customBlocksCommon.emailTheme.subscribe`),
      })
      setActiveModal('success')
    } catch (e) {
      setActiveModal('error')
    }
  }
  const formik = useFormik<FormData>({ initialValues: { email: '' }, onSubmit })

  return (
    <BlockSection {...rest} contentClassName="row center">
      {activeModal != null && (
        <SubmitModal blockType="subscribe" opened onClose={() => setActiveModal(null)} type={activeModal} />
      )}
      <div className={cn('subscribe-block gap-lg-5 gap-4 column', layout + '-lg')}>
        <div className={cn('cell', layout === 'row' ? 'cell-lg-6' : '')}>
          <div className={cn('column')}>
            {title && <h2 className="cell mb-3">{title}</h2>}
            {description && <div className="body-16">{description}</div>}
          </div>
        </div>
        <div className={cn('cell', layout === 'row' ? 'cell-lg-6' : '')}>
          <div className="column gap-2">
            <FormikProvider value={formik}>
              <Form className="row gap-3 center column-sm gap-sm-4">
                <div className={cn('cell-sm-12 cell-md-6', layout === 'column' ? 'cell-5' : 'cell-8')}>
                  <Field
                    component={InputField}
                    iconBefore={<Icon name="mail" size="medium" />}
                    label={t('customBlocksCommon.subscribe.inputPlaceholder')}
                    name="email"
                    validate={chain([required, email])}
                    required
                  />
                </div>
                <div className={cn('cell-sm-12 cell-md-4', layout === 'column' ? 'cell-3' : 'cell-4')}>
                  <Button loading={formik.isSubmitting} buttonType="submit">
                    {t('customBlocksCommon.subscribe.confirmButton')}
                  </Button>
                </div>
              </Form>
            </FormikProvider>
            <PrivacyPolicy privacyPolicy={carrierLoader.data?.privacyPolicy} />
          </div>
        </div>
      </div>
    </BlockSection>
  )
}

export default SubscribeBlock

import React, { ReactElement, ReactNode } from 'react'

import Money from '@components/Money'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'

interface BodyProps {
  title?: string
  description?: ReactNode | string
  warning?: ReactNode
  price?: Money | Money[]
  onClick?: () => void
  controls: ReactNode
  details?: ReactNode
  grouped?: boolean
  pricePerPassenger?: boolean
}

const getLowestPrice = (money: Money[]): Money => money.sort((a, b) => a.fractional - b.fractional)[0]

const Body = (props: BodyProps): ReactElement => {
  const { t } = useTranslation()
  const { title, description, price, controls, details, onClick, grouped, warning, pricePerPassenger } = props
  const totalPrice = Array.isArray(price) ? getLowestPrice(price) : price
  const isMobile = useIsMobile()

  return (
    <div className={bem('extras', 'card-content')}>
      <div className={bem('extras', 'card-content-wrapper', { clickable: !!controls, grouped })} onClick={onClick}>
        <div className="column cell mr-4">
          {title && <h4>{title}</h4>}
          {Boolean(description) && <span className={bem('extras', 'card-description')}>{description}</span>}
          {warning}
          {totalPrice && (
            <div className={bem('extras', 'card-price')}>
              {Array.isArray(price) && <span>{t('price.from')}</span>}
              <span>
                {!Array.isArray(price) && totalPrice.fractional === 0 ? (
                  <span>{t('price.forFree')}</span>
                ) : (
                  <Money fractional={totalPrice.fractional} currency={totalPrice.currency} />
                )}
              </span>
              {pricePerPassenger && <span>{t('price.perPassenger')}</span>}
            </div>
          )}
        </div>
        <div className={bem('extras', 'card-controls')}>{controls}</div>
      </div>
      {!isMobile && details}
    </div>
  )
}

export default Body

/* istanbul ignore file */
import React, { ReactNode, useCallback } from 'react'

import { DeckContext } from '@components/Seats/DeckFrame/context'
import bem from '@lib/bem'

import '@components/Seats/DeckFrame/index.scss'

interface Props {
  children: ReactNode
  orientation: Seat.DeckOrientation
  isTrainHead?: boolean
  size?: Seat.Size
  lights?: boolean
  dense?: boolean
  bus?: boolean
}

const DeckFrame = (props: Props) => {
  const {
    children,
    orientation,
    isTrainHead = false,
    lights,
    dense,
    size = { length: 15, width: 5, height: 1 },
    bus,
  } = props

  const setDeckSize = useCallback(
    (element: HTMLDivElement) => {
      if (element) {
        element.style.setProperty('--rows', size.width.toString())
        element.style.setProperty('--columns', size.length.toString())
      }
    },
    [size.length, size.width],
  )

  return (
    <div
      className={bem('seat-selection', 'deck-wrapper', { [orientation]: true, head: isTrainHead, dense, bus })}
      ref={setDeckSize}
    >
      {lights && (
        <>
          <div className={bem('seat-selection', 'lights', { left: true })} />
          <div className={bem('seat-selection', 'lights', { right: true })} />
        </>
      )}
      <div className={bem('seat-selection', 'deck')}>
        <DeckContext.Provider value={{ orientation, isTrainHead, size }}>{children}</DeckContext.Provider>
      </div>
    </div>
  )
}

export default DeckFrame

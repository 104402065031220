import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { useTranslation } from '@lib/i18n'
import { Input } from '@ui'
import { InputSlotProps, InputSlotRef } from '@ui/Input'

interface Props {
  field: DLocal.Field
  label: string
  formTouched: boolean
  type: string
  onValidStateChanged: (isValid: boolean) => void
}

interface DlocalCustomInputProps extends InputSlotProps {
  field: DLocal.Field
}

const CustomInput = forwardRef<InputSlotRef, DlocalCustomInputProps>(function CustomInput(
  { className, onFocus, onBlur, field },
  ref,
) {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    field.on('focus', onFocus)
    field.on('blur', onBlur)
  }, [field, onBlur, onFocus])

  useEffect(() => {
    /* istanbul ignore else */
    if (containerRef.current) {
      field.mount(containerRef.current)
    }
  }, [field])

  useImperativeHandle(ref, () => ({
    focus: () => {
      field.focus()
    },
  }))

  return <div className={className} ref={containerRef} />
})

const DlocalCardField = ({ field, label, formTouched, onValidStateChanged, type }: Props) => {
  const { t } = useTranslation()
  const [touched, setTouched] = useState(false)
  const [error, setError] = useState<string | null>(() => t('errors.required'))

  useEffect(() => {
    field.on('blur', () => setTouched(true))
    field.on('error', data => {
      setError(data.error.message)
    })
    field.on('empty', ({ empty }) => {
      /* istanbul ignore else */
      if (empty) {
        setError(t('errors.required'))
      }
    })
    field.on('complete', ({ complete }) => {
      /* istanbul ignore else */
      if (complete) {
        setError(null)
      }

      onValidStateChanged(complete)
    })
  }, [field, onValidStateChanged, t])

  return (
    <Input
      dataTag={`dlocalField-${type}`}
      label={label}
      errorMessage={touched || formTouched ? error : null}
      placeholder
      slot={{ Component: CustomInput, props: { field } }}
    />
  )
}

export default DlocalCardField

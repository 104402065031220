import { Field } from 'formik'
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'

import { useTranslation } from '@lib/i18n'
import { required } from '@lib/validators'
import DlocalCardField from '@pages/Checkout/hooks/Payment/dLocal/Card/Field'
import InputField from '@ui/Input/Field'

export interface CardFields {
  form: DLocal.Form
}

export interface CardFormRef {
  field: DLocal.Field
  isValid: boolean
  setTouched: (touched: boolean) => void
}

const style = { base: { fontSize: '16px', lineHeight: '24px' } }
const panPlaceholder = '1234 5678 9012 3456'

const DlocalCardForm = forwardRef<CardFormRef, CardFields>(function DlocalCardForm({ form }, ref) {
  const { t } = useTranslation()
  const [formTouched, setFormTouched] = useState(false)

  const [panValidity, setPanValidity] = useState(false)
  const [cvvValidity, setCvvValidity] = useState(false)
  const [expirationValidity, setExpirationValidity] = useState(false)

  const fields = useMemo(
    () => ({
      pan: form.create('pan', { style, hideIcon: true, placeholder: panPlaceholder }),
      cvv: form.create('cvv', { style }),
      expiration: form.create('expiration', { style }),
    }),
    [form],
  )

  useEffect(() => {
    /* istanbul ignore else */
    if (fields) {
      return () => {
        Object.values(fields).forEach(field => field.destroy())
      }
    }
  }, [fields])

  useImperativeHandle(ref, () => ({
    field: fields.pan,
    isValid: panValidity && cvvValidity && expirationValidity,
    setTouched: touched => setFormTouched(touched),
  }))

  return (
    <div className="row credit-card-form">
      <div className="cell-7 cell-sm-12">
        <div className="column gap-3">
          <div className="cell-12">
            <DlocalCardField
              type="pan"
              field={fields.pan}
              label="Card number"
              onValidStateChanged={setPanValidity}
              formTouched={formTouched}
            />
          </div>
          <div className="cell-12">
            <div className="row gap-3">
              <div className="cell-6">
                <DlocalCardField
                  type="expiration"
                  field={fields.expiration}
                  label="Expiration"
                  onValidStateChanged={setCvvValidity}
                  formTouched={formTouched}
                />
              </div>
              <div className="cell-6">
                <DlocalCardField
                  type="cvv"
                  field={fields.cvv}
                  label="CVC"
                  onValidStateChanged={setExpirationValidity}
                  formTouched={formTouched}
                />
              </div>
            </div>
          </div>
          <div className="cell-12">
            <Field
              validate={required}
              component={InputField}
              name="holderName"
              label={t('checkout.card.holder')}
              required
            />
          </div>
          <div className="cell-12">
            <Field
              validate={required}
              component={InputField}
              name="shopperDocument"
              label={t('checkout.card.shopperDocument')}
              required
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default DlocalCardForm

import { useMemo } from 'react'

import { useCard } from '@pages/Checkout/hooks/Payment/dLocal/useCard'

export const useDLocalMethods = () => {
  const creditCard = useCard()

  return useMemo(
    () => ({
      creditCard,
    }),
    [creditCard],
  )
}
